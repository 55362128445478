export default function (time) {
  let d = new Date(time*1000)

  let y = d.getFullYear()
  let m = d.getMonth() + 1
  m = m >= 10 ? m : `0${m}`
  let dd = d.getDate()
  dd = dd >= 10 ? dd : `0${dd}`
  let hh = d.getHours()
  let mm = d.getMinutes()
  let ss = d.getSeconds()

  hh = hh >= 10 ? hh : `0${hh}`
  mm = mm >= 10 ? mm : `0${mm}`
  ss = ss >= 10 ? ss : `0${ss}`

  return `${y}-${m}-${dd} ${hh}:${mm}:${ss}`
}