<template>
  <div class="page iphone-x">
    <nav-bar show-back="1">
      <round-v></round-v>
    </nav-bar>

    <div class="comments box b-b">
      <div class="list" v-if="newcomments">
        <comment-item
          v-for="(item, index) of newcomments"
          :key="index"
          :info="item"
          :comments="newcomments"
          @delcommentitem="delfn"
        ></comment-item>
      </div>
    </div>
    <div class="zwf"></div>
    <div class="newscomment">
      <button class="btn" @click="btncomment">+ diterbitkan komentar</button>
    </div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ minHeight: '35%', width: '100%' }"
    >
      <div
        class="title"
        style="
          height: 30px;
          width: 100%;
          text-align: center;
          line-height: 30px;
          font-weight: 500;
          font-size: 20px;
          margin: 15px 0px 0px 0px;
        "
      >
        diterbitkan komentar
      </div>
      <div style="margin: 15px 0px; padding: 10px">
        <van-field
          style="background: #f5f5f5; padding: 8px; border-radius: 8px"
          v-model="message"
          rows="2"
          autosize
          border
          type="textarea"
          maxlength="300"
          show-word-limit
          placeholder="Silakan masukkan komentar"
        />
      </div>
      <div class="btnbox">
        <button class="btnqx" @click="btnqx">batalkan</button
        ><button class="bntqr" @click="bntqr">konfirmasi</button>
        <!-- <van-button
          type="primary"
          size="small"
          @click="btnqx"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          class="affirm"
           hairline 
          >batalkan</van-button
        >
        <van-button type="primary" size="small" @click="bntqr" color="#07c160"  hairline 
        class="cancel"
          >konfirmasi</van-button
        > -->
        <!-- <van-button type="primary">朴素按钮</van-button>
        <van-button type="info">朴素按钮</van-button> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
//component
import NavBar from "../../components/navBar";
import RoundV from "../../components/roundV";
import CommentItem from "../../components/commentItem";
//api
import service from "../../service";
import storage from "@/utils/storage";
import { Toast } from "vant";
//import { Toast } from "vant";
//let activityId = 1
export default {
  data() {
    return {
      activityId: this.$route.query.goodid,
      username: storage.get("username"),
      //token: storage.get("token"),
      userid: storage.get("userid"),
      avatar: storage.get("avatar"),
      comments: [],
      // newcomments:[],
      message: "",
      show: false,
      info: "",
    };
  },
  components: {
    NavBar,
    RoundV,
    CommentItem,
  },
  computed: {
    newcomments() {
      return this.comments;
    },
  },
  created() {
    this.$showLoading();
    this.getComments();
    window.track("wowsaa-groups", "comments", "expose");
  },
  destroyed() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    getComments() {
      return service
        .getComments(this.activityId, { page_size: 100 })
        .then((data) => {
          this.comments = (data && data.comments) || [];
        })
        .catch(() => {
          this.comments = [];
        })
        .then(this.$hideLoading);
    },
    //评论弹窗
    btncomment() {
      if (!this.userid) {
        Toast({ message: "Anda belum mendarat ! " });
        this.show = false;
      } else {
        this.show = true;
      }
    },
    btnqx() {
      this.show = false;
      this.message = "";
    },
    timer() {
      return setTimeout(() => {
        this.getComments();
      }, 0);
    },
    bntqr() {
      if (this.message == "") {
        Toast({ message: "Apa yang dimasukkan tidak boleh kosong ! " });
      } else {
        service
          .addComment(this.activityId, {
            user_id: this.userid,
            content: this.message,
          })
          .then(() => {
            Toast({
              message: "Komentar berhasil ",
              icon: "completed",
            });
               this.getComments();
          });    
        this.message = "";
        this.show = false;
      }
    },
    delfn(){ 
      // this.newcomments.forEach((ele,index) => {
      //   this.newcomments.splice(index,1)
      // });
      // service.deleteComment(id).then(res=>{
      //   console.log(res)
      //   alert("删除了")
      // })
    }
  },
  watch: {
    //执行延时器刷新数据
    // comments() {
    //   this.timer();
    // },
    comments(){
      this.$nextTick(()=>{
         this.getComments();
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  @extend %page;
  background-color: $c11;
  position: relative;
}
.newscomment {
  height: pxTo(45);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  background: #f5f5f5;
  width: 100%;
  // padding: 5px;
  text-align: center;
  // line-height: pxTo(45);

  .btn {
    display: block;
    // margin-top: pxTo(5);
    // margin-left: pxTo(25);
    border-radius: pxTo(10);
    width: 100%;
    height: 100%;
    // border: 1px solid #99cc00;
    background-image: linear-gradient(90deg, #ffb400 0%, #ff419f 100%);
    color: #fff;
    // height: pxTo(42);
    //     background: $bg1;
    //     background-size: 100% 100%;
    //     border-radius: pxTo(6);
    //     text-align: center;

    // @extend %ff-rb;
    // @include fs-l-w-c(14, 42, 900, $c2);
  }
}
.zwf {
  width: 100%;
  height: pxTo(70);
  // margin-top: pxTo(70);
}
.btnbox {
  width: 100%;
  height: pxTo(74);
  margin-top: pxTo(15);
  // padding: pxTo(5) pxTo(10);
  line-height: 74px;
  display: flex;
  justify-content: space-evenly;
}
.btnqx {
  //display: inline-block;
  width: pxTo(110);
  height: pxTo(30);
  font-size: pxTo(16);
  text-align: center;
  background: lighten(#fa983a, 15%);
  line-height: pxTo(20);
  color: #fff;
  border-radius: pxTo(5);
}
.bntqr {
  // display: inline-block;
  width: pxTo(110);
  height: pxTo(30);
  font-size: pxTo(16);
  text-align: center;
  background: #b8e994;
  line-height: pxTo(20);
  color: #fff;
  border-radius: pxTo(5);
}
.comments {
  margin-top: 0 !important;
  .comments-tags {
    padding-top: pxTo(16);
    padding-left: pxTo(12);
    padding-bottom: pxTo(6);
  }

  // .list {
  // }
  .title {
    height: 30px;
    width: 100%;
    text-align: center;
    line-height: 30px;
    font-weight: 500;
    font-size: 20px;
    margin: 35px 0px 0px 0px;
  }
}
</style>