<template>
  <div class="round-v">
    <div class="list" v-if="list">
      <vue-seamless-scroll :data="list" :class-option="options">
        <div class="item fx-row fx-center" v-for="(item, index) of list" :key="index">
          <div class="avatar">
            <div :style="{backgroundImage: getAvatarBg(item.avatar)}"></div>
          </div>
          <div class="fx-1 info">
            <div>{{getText(item.username || '')}}</div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
  import formatBackgroundImgUrl from '../filters/formatBackgroundImgUrl'
  import VueSeamlessScroll from 'vue-seamless-scroll'
  import service from '../service'

  export default {
    data() {
      return {
        list: false,
        options: {
          step: 0.5,
          direction: 1,
          openWatch: false
        }
      }
    },
    props: ['type'],
    components: {
      VueSeamlessScroll
    },
    methods: {
      init() {
        this.getSuccessMembersTop10()
      },
      getSuccessMembersTop10() {
        service.getSuccessMembersTop10().then((data) => {
          this.list = data || false
        })
      },
      getAvatarBg(avatar) {
        return formatBackgroundImgUrl(avatar)
      },
      getText(username) {
        return `${username.toUpperCase()} TELAH MEMBELI PRODUK`
      }
    },
    created() {
      this.init()
    }
  }
</script>

<style lang="scss" scoped>
  .round-v {
    width: pxTo(280);
    height: 100%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      background: $bg4;
    }

    .list {
      height: 100%;
    }

    .item {
      padding: 0 pxTo(17);
      height: pxTo(30+5);

      .avatar {
        width: pxTo(24);
        height: pxTo(24);
        padding: pxTo(2);
        background: $bg1;
      }

      .info {
        margin-left: pxTo(10);

        > div {
          max-width: pxTo(210);
          height: pxTo(17);

          @extend %ellipsis;
          @extend %ff-rb;
          @include fs-l-w-c(12, 17, 700, $c2);
        }
      }
    }
  }
</style>