<template>
  <div class="item b-t">
    <div class="user fx-row fx-center">
      <div class="avatar" :style="{backgroundImage:avatarBg}"></div>
      <div class="name fx-1">{{info.username}}</div>
    </div>
    <div class="txt">{{info.content}}</div>
    <!--div class="user-tags">
      <div class="tag gray" v-for="(item, index) of info.tags" :key="index">{{item}}</div>
    </div-->
  <!-- <div class="del" @click="delcomment">删除</div> -->
    <div class="date">
      {{info.created_at | formatDate}}
    </div>
  </div>
</template>

<script>
  import formatBackgroundImgUrl from '../filters/formatBackgroundImgUrl'
  import formatDate from '../filters/formatDate'
 
  export default {
    props: ['info','comments'],
    filters: {
      formatDate
    },
    data(){
      return{
        newcomments:this.comments
      }
    },
    computed: {
      avatarBg() {
        return formatBackgroundImgUrl(this.info && this.info.avatar || '')
      }
    },
    methods:{
      delcomment(){
        this.$emit('delcommentitem',this.info.id)          
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    padding-bottom: pxTo(16);

    .user {
      height: pxTo(36);
      padding: 0 pxTo(10);

      .avatar {
        width: pxTo(18);
        height: pxTo(18);
        border-radius: 50%;
        overflow: hidden;
        background-color: $c14;
      }

      .name {
        height: pxTo(11);
        margin-left: pxTo(8);

        @extend %ff-rr;
        @include fs-l-w-c(10, 11, 400, $c10);
      }
    }
    .del{
      font-size: pxTo(12);
      float:right;
      margin-right: pxTo(12);
      color: #5D5D5D;
    }
    .txt {
      padding-left: pxTo(36);
      padding-right: pxTo(16);
      margin-top: pxTo(6);

      @extend %ff-rr;
      @include fs-l-w-c(12, 14, 400, $c10);
    }

    .user-tags {
      padding-left: pxTo(36);
      padding-right: pxTo(16);
      margin-top: pxTo(10);
    }

    .date {
      padding-left: pxTo(36);
      padding-right: pxTo(16);
      margin-top: pxTo(10);

      @extend %ff-rr;
      @extend %ellipsis;
      @include fs-l-w-c(11, 13, 400, $c12);
    }
  }
</style>